import './App.css';
import React, { useState } from 'react';
import Editor from './components/Editor';
import Delete from './components/delete';

function App() {
  const [editor,setEditor] = useState("Add")

  const [quiz,setQuiz] = useState("")

  const [warn,setWarn] = useState(false)
  return (
    <div className="App"  style={warn ? {backgroundColor:"rgb(0 0 0 / 11%)",height:" 100vh",} : 
    {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        background: "transparent",
        color: "black",
        height:" 100%",}
    }>
      
      <section className='Header'>
        <button onClick={()=>setEditor("Add")} className={editor == "Add" ? "selected" : "notsel" }>Add</button>
        <button onClick={()=>setEditor("Delete")} className={editor == "Delete" ? "selected" : "notsel" }>Delete</button>
      </section>

     {editor == "Add" &&
      <Editor quiz={quiz} />}
     {editor == "Delete" &&
      <Delete setEditor={setEditor} setQuiz={setQuiz} quiz={quiz} setWarn={setWarn} warn={warn} />}
    </div>
  );
}

export default App;
