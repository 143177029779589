import React, { useState } from "react";
import editorStyles from "../styles/Editor.module.scss";
import { message } from "antd";

import axios from "axios";

const Editor = ({quiz}) => {
  const [data, setData] = useState({
    courseName: quiz?.courseName,
    title: quiz?.title,
    question: quiz?.question,
    option1: quiz?.option1,
    option2: quiz?.option2,
    option3: quiz?.option3,
    option4: quiz?.option4,
    answer: quiz?.answer,
    ansExplain: quiz?.ansExplain,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        data.courseName === "" ||
        data.title === "" ||
        data.question === "" ||
        data.option1 === "" ||
        data.option2 === "" ||
        data.option3 === "" ||
        data.option4 === "" ||
        data.answer === ""
      ) {
        messageApi.open({
          type: "warning",
          content: "Cannot Submit Empty Fields",
        });

        throw new Error("Submitted Empty Data");
      } else {
        if(quiz?._id){
          const sendData = await axios.post(
            "https://rest.techiepanda.in/updateQuiz",
            data
          );

          if (sendData) {
            messageApi.open({
              type: "success",
              content: "Successfully Updated",
            });
  
            setData({
              title: "",
              question: "",
              option1: "",
              option2: "",
              option3: "",
              option4: "",
              answer: "",
              ansExplain: "",
            });
            console.log(sendData);
            console.log(data);
          } else {
            messageApi.open({
              type: "error",
              content: "Error While Uploading",
            });
            throw new Error("No data");
          }
        }else{

            const sendData = await axios.post(
              "https://server.techiepanda.in/quiz/addQuiz",
              data
            );
            if (sendData) {
              messageApi.open({
                type: "success",
                content: "Successfully Updated",
              });
    
              setData({
                title: "",
                question: "",
                option1: "",
                option2: "",
                option3: "",
                option4: "",
                answer: "",
                ansExplain: "",
              });
              console.log(sendData);
              console.log(data);
            } else {
              messageApi.open({
                type: "error",
                content: "Error While Uploading",
              });
              throw new Error("No data");
            }
          }
        }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      {contextHolder}
      <main>
        <form className={editorStyles.form} onSubmit={handleSubmit}>
          <label>Enter the course name here :</label>
          <input
            type="text"
            value={data.courseName}
            placeholder="FRONT END INTERNSHIP PROGRAM"
            onChange={(e) => setData({ ...data, courseName: e.target.value })}
          />
          <label>Enter the Title here :</label>
          <input
            type="text"
            value={data.title}
            placeholder="what is HTML?"
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />

          <label>Enter the question here :</label>
          <textarea
            rows={5}
            value={data.question}
            type="text"
            onChange={(e) => setData({ ...data, question: e.target.value })}
          />

          <label>Option1 :</label>
          <input
            type="text"
            value={data.option1}
            onChange={(e) => setData({ ...data, option1: e.target.value })}
          />

          <label>Option2 :</label>
          <input
            type="text"
            value={data.option2}
            onChange={(e) => setData({ ...data, option2: e.target.value })}
          />

          <label>Option3 :</label>
          <input
            type="text"
            value={data.option3}
            onChange={(e) => setData({ ...data, option3: e.target.value })}
          />

          <label>Option4 :</label>
          <input
            type="text"
            value={data.option4}
            onChange={(e) => setData({ ...data, option4: e.target.value })}
          />
          <label>Enter Answer Here :</label>
          <input
            type="text"
            value={data.answer}
            onChange={(e) => setData({ ...data, answer: e.target.value })}
          />

          <label>Enter the Answer explanation here :</label>
          <textarea
            type="text"
            value={data.ansExplain}
            
            rows={10}
            onChange={(e) => setData({ ...data, ansExplain: e.target.value })}
          />
          <label></label>
          <input type="submit" value={quiz._id ? "update" :"submit"} />
        </form>
      </main>
    </div>
  );
};

export default Editor;
