import axios from 'axios'
import React, { useState } from 'react'
import '../App.css';
const Delete = ({quiz,setQuiz,setEditor,warn,setWarn}) => {

    const [inputVal,setInputVal] = useState("")



    const searched =async () => {
        const {data} = await axios.post("https://rest.techiepanda.in/getQuizTitle",{QuizTitle:inputVal}) 

        setQuiz(data)
    }

    const toggleWarn = ()=>{
        setWarn(!warn)
    }

    const deleteQuiz = async () =>{
        const {data} = await axios.post("https://rest.techiepanda.in/deleteQuiz",{QuizId:quiz._id}) 

      alert("quiz deleted")
    }


  return (
    <div style={{position:"relative"}}>
        <div className='delhead'>
            <input 
                placeholder='Search quiz with title to delete '

                onChange={(e)=>setInputVal(e.target.value)}
            />
            <button onClick={searched}>Search</button>

        </div>
        {quiz &&
            <div  className='delcard'>
                <div>
                    <span> <strong> courseName : </strong> {quiz.courseName}<br /></span>
                    <span><strong> title: </strong> {quiz.title}<br /> </span>
                    <span> <strong>question :</strong>  {quiz.question}<br /></span>

                </div>
                <br/>

                <section className='delBottom'>
                    <button onClick={()=>setEditor("Add")}>Edit</button>
                    <button  onClick={()=>toggleWarn(true)}>Delete</button>
                </section>

            </div>
        }
        {warn && <div className='popup'>
                <div>Are you sure want to delete this quiz</div>
                <section className='delBottom'>
                    <button onClick={()=>toggleWarn(false)}>Cancel</button>
                    <button onClick={deleteQuiz}>Delete</button>
                </section>
            </div>}
    </div>
  )
}

export default Delete
